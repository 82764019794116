import {
  GET_ERROR,
  CREATE_PRODUCT,
  GET_ALL_PRODUCT,
  GET_PRODUCT_DETAILS,
  GET_SIMILAR_PRODUCTS,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  GET_ALL_PRODUCT_CATEGORY,
  GET_ALL_PRODUCT_SUB_CATEGORY,
  GET_ALL_PRODUCT_BRAND,
  GET_SALES_REPORT,
  UPDATE_PRODUCT_STATUS,
  GET_ALL_PRODUCT_ADMIN,
  ALL_PRODUCTS_FILTER_FOR_ADD_TO_OFFER,
  GET_ALL_OFFERS,
  PRODUCTS_FEATURED_LIMIT,
  ALL_PRODUCTS_FOR_EXCEL,
  LOADING_ADMIN,
  GET_SEARCH_PRODUCTS,
  RESET_STORED_PRODUCTS,
  WAIT_TIME_FOR_HOME_PAGE
} from "../type";
const inital = {
  product: [],
  allProducts: [],
  oneProduct: [],
  updateProduct: [],
  updateProductStatus: [],
  similarProducts: [],
  deleteProduct: [],
  productsByCategory: [],
  productsByBrand: [],
  salesReport: [],
  allProductsAdmin: [],
  allProductsNoOfferAndFilter: [],
  allOffersInHomePage: [],
  productFeatured:[],
  excelProduct:[],
  allProductsInSearchPage:[],

  loading: true,
  loadingAdmin:false,
  keyword:'',
  waitTime:true
};

const productReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT:
      return {
        ...state,
        allProducts: action.payload,
        keyword:action.keyword,
        loading: false,
        loadingAdmin:false
      };
    case GET_ALL_PRODUCT_ADMIN:
      return {
        ...state,
        allProductsAdmin: action.payload,
        loading: false,
      };

    case CREATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        deleteProduct: action.payload,
        loading: false,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        updateProduct: action.payload,
        loading: false,
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        oneProduct: action.payload,
        loading: false,
      };
    case GET_SIMILAR_PRODUCTS:
      return {
        ...state,
        similarProducts: action.payload,
        loading: false,
      };
    case GET_ALL_PRODUCT_CATEGORY:
      return {
        ...state,
        productsByCategory: action.payload,
        loading: false,
      };

    case GET_ALL_PRODUCT_BRAND:
      return {
        ...state,
        productsByBrand: action.payload,
        loading: false,
      };

    case GET_SALES_REPORT:
      return {
        ...state,
        salesReport: action.payload,
        loading: false,
      };

    case GET_ALL_PRODUCT_SUB_CATEGORY:
      return {
        ...state,
        productsBySubCategory: action.payload,
        loading: false,
      };

    case GET_ERROR:
      return {
        ...state,
        product: action.payload,
        loading: true,
      };
    case UPDATE_PRODUCT_STATUS:
      return {
        ...state,
        updateProductStatus: action.payload,
        loading: true,
      };
    case ALL_PRODUCTS_FILTER_FOR_ADD_TO_OFFER:
      return {
        ...state,
        allProductsNoOfferAndFilter: action.payload,
        loading: true,
      };
    case GET_ALL_OFFERS:
      return {
        ...state,
        allOffersInHomePage: action.payload,
        loading: true,
      };
      case PRODUCTS_FEATURED_LIMIT:
        return {
          ...state,
          productFeatured: action.payload,
          loading: true,
        };

      case ALL_PRODUCTS_FOR_EXCEL:
        return{
          ...state,
          excelProduct:action.payload
        }
      case LOADING_ADMIN:
        return{
          ...state,
          loadingAdmin:true
        }
      case GET_SEARCH_PRODUCTS:
        return{
          ...state,
          allProductsInSearchPage:action.payload,
          keyword:action.keyword,
          loading: false,
          loadingAdmin:false
        }
      case RESET_STORED_PRODUCTS:
        return{
          ...state,
          allProducts:[]
        }
      
        case WAIT_TIME_FOR_HOME_PAGE:
          return{
            ...state,
            waitTime:false
          }

    default:
      return state;
  }
};

export default productReducer;
