import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../../hook/useNotification";
import { createNewUser } from "../../redux/actions/authActions";

const RegisterHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const onChangeFirstname = (e) => {
    setFirstname(e.target.value);
  };
  const onChangeLastname = (e) => {
    setLastname(e.target.value);
  };
  const onChangeMobile = (e) => {
    setMobile(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  const onChangeAcceptTerms = (e) => setAcceptTerms(e.target.checked);

  // const validationValues = () => {};
  const res = useSelector((state) => state.authReducers.createUser);

  const onSubmit = async () => {
    if (firstname === "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }
    if (lastname === "") {
      notify("من فضلك ادخل الاسم الاخير", "error");
      return;
    }
    if (mobile.length <= 9) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل البريد الالكتروني", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل كلمة المرور", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك ادخل تاكيد كلمة المرور", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("من فضلك تاكد من كلمه المرور", "error");
      return;
    }
    if (!acceptTerms) {
      notify("يجب المولفقه علي الشروط و الاحكام", "error");
      return;
    } else {
      setLoading(true);
      await dispatch(
        createNewUser({
          firstname: firstname,
          lastname: lastname,
          mobile: mobile,
          email: email.toLocaleLowerCase(),
          password: password,
        })
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    if (loading === false) {
      if (res) {
        if (res.isVerified === false) {
          navigate(`/verifyCode/${res._id}`);
        } else {
          if (res.token) {
            localStorage.setItem("token", res.token);
            localStorage.setItem("user", JSON.stringify(res));

            notify("تم تسجيل الدخول بنجاح", "success");
            setTimeout(() => {
              if (res.role === "admin") {
                window.location.href = "/admin/products";
              } else {
                window.location.href = "/";
              }
            }, 1500);
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("page");
          }
        }

        console.log('res.data',res.data);

        if (res.data) {
          if (
            res.data === "password is wrong" ||
            res.data === "The user not found"
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            notify("خطأ في البريد الالكتروني أو كلمة المرور   ", "error");
          }
          if (res.data === "The user is blocked") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            notify("هذا المستخدم محظور من قبل الادارة", "error");
          }

          if(res.data==="email or mobile already exists"){
            notify("هذا الايميل او رقم الهاتف موجود بالفعل", "error");
 
          }
        }

        setLoading(true);
      }
    }
  }, [loading]);

  return [
    firstname,
    lastname,
    mobile,
    email,
    password,
    confirmPassword,
    acceptTerms,
    loading,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeAcceptTerms,
    onSubmit
  ];
};

export default RegisterHook;
