import { useGetData } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { GET_ACCESS_TOKEN_TAOBAO,GET_TAOBAO_PRODUCTS,RESET_TAOBAO_PRODUCTS,SAVE_TAOBAO_PRODUCT } from "../type"


export const getAccessToken=()=>async(dispatch)=>{
        try {
            const response = await useGetData(`/api/v1/taobao/createAccessToken`);

            dispatch({
                type:GET_ACCESS_TOKEN_TAOBAO,
                payload:response
            });
        } catch (error) {
            dispatch({
                type: GET_ACCESS_TOKEN_TAOBAO,
                payload: error,
              });
        }
}

export const getTaobaoProducts=(body)=>async(dispatch)=>{
    try {
        const response = await useInsertData(`/api/v1/taobao/getProduct`, body);

        dispatch({
            type:GET_TAOBAO_PRODUCTS,
            payload:response
        });

    } catch (error) {
        dispatch({
            type: GET_TAOBAO_PRODUCTS,
            payload: error,
          });
    }
}

export const addTaoBaoProduct=(body)=>async(dispatch)=>{
    try {
        const response = await useInsertData(`/api/v1/taobao/saveProduct`, body);

        dispatch({
            type:SAVE_TAOBAO_PRODUCT,
            payload:response
        });

    } catch (error) {
        dispatch({
            type: SAVE_TAOBAO_PRODUCT,
            payload: error,
          });
    }
}

// RESET_TAOBAO_PRODUCTS
export const resetStoredTaoBaoProducts=()=>(dispatch)=>{
    try {
        dispatch({
            type: RESET_TAOBAO_PRODUCTS
          });
    } catch (error) {
        dispatch({
            type: RESET_TAOBAO_PRODUCTS,
            payload: error,
          });
    }
}