import React from "react";
import SideBar from "../../Components/Admin/SideBar";
import AdminProductsFromDropShipping from "../../Components/Admin/AdminProductsFromDropShipping";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllDropShippings } from "../../redux/actions/dropShippingAction";
import CustomButton from "../../Components/Utilities/CustomButton";
import { useState } from "react";
import { useRef } from "react";
import CustomSelect from "../../Components/Utilities/CustomSelect";
import { getAccessToken } from "../../redux/actions/taobaoAction";


//dropShippingReducer
const AdminProductsFromDropShippingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState("");
  let selectRef = useRef();

  useEffect(() => {
    dispatch(getAllDropShippings());

    dispatch(getAccessToken());

  }, [dispatch]);

  
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const dropShippingMethods = useSelector((state) => state.dropShippingReducer.allDropShippings);

 // console.log('accessTokenObj',accessTokenObj);

  const handleClick = () => {
    if (selectRef.current.value == '') return;
    setSelectValue(selectRef.current.value);
    // console.log('doneeeeeeeeee');

  }


  return (
    <div className="d-flex justify-content-start">
      <Helmet>
        <title>{`${t("addProductsDropShippings")}  -  ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
        <div className="admin-content-text pb-4">{t("addProductsDropShippings")}</div>
        <label htmlFor="dropShippingMethods" className="d-block mt-2">{t('please choose DropShipping Method')}</label>
        <div className="d-flex justify-content-between mt-3 px-2">

          <CustomSelect
            id="dropShippingMethods"
            selectRef={selectRef}
            object={dropShippingMethods}
            defaultOption={t("please choose DropShipping Method")}
            type="ref"
          />

          <CustomButton
            onClick={handleClick}
          >
            {t("choose")}
          </CustomButton>
        </div>

         {/*
          استيراد بطريقة droppast
         <AdminProductsFromDropShipping />
          */}

        {
          selectValue == '1' && <AdminProductsFromDropShipping />
        }

      </div>
    </div>
  );
};

export default AdminProductsFromDropShippingPage;

