import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import avatar from "../../Images/avatar.png";
import { ToastContainer } from "react-toastify";
import add from "../../Images/add.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { CompactPicker } from "react-color";
import AdminAddSliderHook from "../../hook/admin/AdminAddSliderHook";
import { Helmet } from "react-helmet";
import MultiImageInput from "react-multiple-image-input";

const AdminAddSlider = () => {
  const { t } = useTranslation();
  const [
    link,
    onChangeLink,
    title,
    onChangeTitle,
    description,
    onChangeDescription,
    handleSubmit,
    colors,
    onChangePicker,
    handleChaneComplete,
    showPicker,
    settingsRes,
    secondaryColor,
    page,
    key,
    onChangeKey,
    handleChaneCompleteSecond,
    link2,
    onChangeLink2,
    images,
    setImages,
    check
  ] = AdminAddSliderHook();

  const maxFileSize = 1 * 1024 * 1024; // 2 MB in bytes
  const getImageSizeFromBase64 = (base64) => {
    // Calculate file size from Base64 string
    const stringLength = base64.length - 'data:image/png;base64,'.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812; // Base64 size in bytes
    return sizeInBytes;
  };

  const handleSetImages = (newImages) => {
    const validatedImages = {};
    for (const [key, base64Image] of Object.entries(newImages)) {
      const imageSize = getImageSizeFromBase64(base64Image);
      if (imageSize <= maxFileSize) {
        validatedImages[key] = base64Image;
      } else {
        alert(`Image size exceeds 1 MB: ${key}`);
      }
    }
    setImages(validatedImages);
  };

  return (
    <div>
      <Helmet>
        <title>{`  ${t("Add Slider Image")} - ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("Add Slider Image")}</div>
        <Col sm="8">
          <div className="mt-3">
          <MultiImageInput
            images={images}
            setImages={handleSetImages}
            theme="light"
            max={2}
            allowCrop={false}
          />
        </div>
          <label htmlFor="link" className="d-block mt-2 ">
            {" "}
            {t("ads link")}{" "}
          </label>
          <input
            id="link"
            value={link}
            onChange={onChangeLink}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Link")}
          />

          <label htmlFor="link" className="d-block mt-2 ">
          {" "}
          {t("ads link")}{"2 "}
        </label>
        <input
          id="link"
          value={link2}
          onChange={onChangeLink2}
          type="text"
          className="input-form d-block mt-2 px-3"
          placeholder={t("Link")}
        />

          <label htmlFor="title" className="d-block mt-2 ">
            {" "}
            {t("address")}{" "}
          </label>
          <input
            id="title"
            value={title}
            onChange={onChangeTitle}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("address")}
          />
          <label htmlFor="description" className="d-block mt-2 ">
            {t("details")} {" "}
            {" "}
          </label>
          <input
            id="description"
            value={description}
            onChange={onChangeDescription}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("details")}
          />
          <div className="text-form mt-3 "> {t("Available colors for the product")}</div>
          <div className="mt-1 d-flex">
            {colors && (
              <div
                className="color ms-2 border  mt-1"
                style={{ backgroundColor: colors }}
              ></div>
            )}
            <img
              src={add}
              alt=""
              width="30px"
              height="35px"
              style={{ cursor: "pointer" }}
              onClick={onChangePicker}
            />

            {showPicker === true ? (
              <CompactPicker onChangeComplete={handleChaneComplete} />
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
      <Col sm="8" className="d-flex justify-content-end ">
        {check === true ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        )}
      </Col>
    </Row>


      <ToastContainer />
    </div>
  );
};

export default AdminAddSlider;
