import React from "react";
import { Navbar, Row } from "react-bootstrap";
import SideBarSearchHook from "../../hook/search/SideBarSearchHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { BsArrowDownCircle } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";

const SideFilter = (props) => {
  const { t } = useTranslation();
  //var { selectedCategorySearch, ...otherProps } = this.props;
  const[searchParams,setSearchParams]=useSearchParams();

  const [category, brands] = SideBarSearchHook();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  console.log('searchCategories',props.props?.searchCategories);

 // console.log('props.props?.searchBrand',props.searchBrand)
  // console.log('props.minPrice');
  // console.log(props.minPrice);

  // console.log("[...searchParams.get('search_brand_param')]", searchParams.get('search_brand_param').split(','))
  // console.log("searchParams.get('search_brand_param')",searchParams.get('search_brand_param').split(',').find(ele=>ele=='63a9aeff4551274a3e6f6324'))
  return (
    <Navbar
      expand="sm"
      className="d-flex align-items-start navSideFilter"
      style={{ zIndex: 1000 }}
    >
      <div>
        <Navbar.Toggle aria-controls="basic-sideBar-nav" className="border-2">
          <BsArrowDownCircle /> Filter{" "}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-sideBar-nav">
          <Row className="sideFilterRow">
            <div className="d-flex flex-column mt-2">
              {props.props?.hideCategory !== true ? (
                <>
                  <div className="filter-title">{t("main classification")}</div>
                  {category
                    ? category.map((el) => {
                        return (
                          <div key={el._id} className="d-flex mt-2 w-100">
                            <input
                              type="checkbox"
                              className="category"
                              value={el._id}
                              onClick={(e) =>
                                props.props.selectedCategorySearch(e)
                              }
                              checked={props.props?.searchCategories?.find(ele=>ele==el?._id) ? true : false}

                            />

                            <div
                              title={
                                Cookies.get("i18next") === "ar"
                                  ? el.nameAr
                                  : el.nameEn
                              }
                              className="filter-sub  "
                            >
                              {Cookies.get("i18next") === "ar"
                                ? el.nameAr
                                : el.nameEn}
                            </div>
                          </div>
                        );
                      })
                    : null}
                </>
              ) : null}
            </div>

            {props.props?.hideBrands !== true ? (
              <div className="d-flex flex-column mt-2">
                <div className="filter-title mt-3">الماركة</div>

                {brands
                  ? brands.map((el) => {
                      return (
                        <div key={el._id} className="d-flex mt-2">
                          <input
                            type="checkbox"
                            className="brand"
                            value={el._id}
                            onClick={(e) => props.props.selectedBrandSearch(e)}
                            checked={props.props?.searchBrand?.find(ele=>ele==el?._id) ? true : false}
                          />
                          <div
                            style={{ width: "100px" }}
                            title={
                              Cookies.get("i18next") === "ar"
                                ? el.nameAr
                                : el.nameEn
                            }
                            className="filter-sub me-1 "
                          >
                            {Cookies.get("i18next") === "ar"
                              ? el.nameAr
                              : el.nameEn}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            ) : null}

            <div className="filter-title mt-3">السعر</div>
            <div className="d-flex mt-2">
              <p className="filter-sub my-2">من:</p>
              <input
                className="m-1 "
                type="number"
                onChange={(e) => props.props.onChangeSetMinPrice(e)}
                value={props.props.minPrice}
                style={{ width: "50px", height: "26px" }}
                id="from"
              />
            </div>
            <div className="d-flex">
              <p className="filter-sub my-2">الي:</p>
              <input
                className="m-1"
                type="number"
                value={props.props.maxPrice}
                onChange={(e) => props.props.onChangeSetMaxPrice(e)}
                style={{ width: "50px", height: "26px" }}
                id="to"
              />
            </div>

            <button
              className="btn-save  mt-2 d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: settingsRes?.data?.cartColor ? settingsRes?.data?.cartColor : settingsRes?.data?.primaryColor,
                width: "40%",
                marginRight: "6px",
                marginBottom:'15px',
                fontSize:"12px"
              }}
              onClick={() => props.props.search(0)}
            >
              <span> {t("search")} </span>
              <i className="fa fa-search p-2 d-flex justify-content-center"></i>
            </button>

            <button
              className="btn-save  mt-2 d-flex justify-content-center align-items-center"
              onClick={() => props.props.search(1)}
              style={{
                backgroundColor: settingsRes?.data?.cartColor ? settingsRes?.data?.cartColor : settingsRes?.data?.primaryColor,
                width: "50%",
                marginRight: "6px",
                marginBottom:'15px',
                fontSize:"12px"
              }}
            >
              حذف الشروط
            </button>
          </Row>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default SideFilter;
