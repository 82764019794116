import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import CategoryContainer from "../../Components/Category/CategoryContainer";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";
import Pagination from "../../Components/Utilities/Pagination";
import CatPageHook from "../../hook/category/CatPageHook";
import SubCategoryContainer from "../../Components/SubCategory/SubCategoryContainer";
import SubCategoryHook from "../../hook/category/SubCategoryHook";
import { useParams } from "react-router-dom";

const SubCategoryPage = () => {

  const { id } = useParams();

  const [category, loading, loadingSpinner, colors] = SubCategoryHook(id);

  const [waitTime, setWaitTime] = useState(true);

  setTimeout(()=>{
    setWaitTime(false);
  // dispatch(EndWaitTimeForHomePage());
},1500);
  return (

    <Col md="12" sm="4">
      {loading === true ? (
        <LoadingSpinner />
      ) : (
        <>
          <div style={{ display: waitTime == true ? 'block' : 'none' }}>
            <LoadingSpinner />
          </div>
          <div style={{ display: waitTime == true ? 'none' : 'block' }}>
            <SubCategoryContainer category={category} loading={loading} />
          </div>

        </>
      )}
    </Col>


  );
};

export default SubCategoryPage;
