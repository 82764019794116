

import React from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import WalletUserTransactionHook from "../../hook/user/WalletUserTransactionHook";

const WalletUserTransaction = () => {
  const { t } = useTranslation();

  const [userTransactions] = WalletUserTransactionHook();
  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "numeric", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };

    // console.log('userTransactions')
    //  console.log(userTransactions)


  let columns = [
    {
      name: t("type"),
      selector: (row) => (row.type == 1 ? t("deposit") : t("withdraw")),
      sortable: true,
    },
    {
      name: t("price"),
      selector: "price",
      sortable: true,
    },
    {
      name: t("Added by"),
      selector: (row) => row.addedBy?.firstname + `  ` + row.addedBy?.lastname,
      sortable: true,
    },


    {
        name: t("date"),
        selector: (row) => row?.createdAt?.split('T')[0],
        sortable: true,
      },

      // formatDate(row?.createdAt)
   
  ];

  const tableData = {
    columns,
    data: userTransactions?.walletList,
  };

  return (
    <div>
      <Helmet>
        <title>{`  ${t("Wallet Reports")}`}</title>
      </Helmet>

      <div className="d-flex justify-content-between mt-2">
      <div className="admin-content-text">{t("Wallet Reports")}</div>
      <div className="admin-content-text">{t("current balance")} : {userTransactions?.userCredit}</div>
      <div></div>

    
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default WalletUserTransaction;



