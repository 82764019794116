import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategories,
  getAllCategoriesWithoutBlockedCategory,
  getAllCategoryPage,
} from "../../redux/actions/categoryAction";
import { useLocation } from "react-router-dom";
//import axios from "axios";

const CatPageHook = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  useEffect(() => {
    if (location.pathname === "/allcategory") {
     // const cancelToken = axios.CancelToken.source();
      setLoadingSpinner(true);
      dispatch(getAllCategoriesWithoutBlockedCategory());
      setLoadingSpinner(false);
    }

    else if (location.pathname === '/') {
      setLoadingSpinner(true);
      const categoriesLimit=10;

      dispatch(getAllCategoriesWithoutBlockedCategory(categoriesLimit));
      setLoadingSpinner(false);
    }
    // else {
    //   setLoadingSpinner(true);
    //   dispatch(getAllCategoriesWithoutBlockedCategory());
    //   setLoadingSpinner(false);
    // }

    window.scrollTo(0, 0);
  }, [dispatch, location.pathname]);
  const category = useSelector((state) => state.allCategory.categoriesWithoutBlocked);
  const loading = useSelector((state) => state.allCategory.loading);

  console.log('location.pathname', location.pathname);
  // let pageCount = 0;
  // if (category.numberOfPages) pageCount = category.numberOfPages;

  // const getPage = (page) => {
  //   dispatch(getAllCategoryPage(page));
  // };

  const colors = [
    "#FFD3E8",
    "#F4DBA5",
    "#55CFDF",
    "#FF6262",
    "#0034FF",
    "#FFD3E8",
  ];

  return [category, loading, loadingSpinner, colors];
};

export default CatPageHook;
