import React, { useEffect, useState } from "react";
import { addCoupon, deleteCoupon, getAllCoupon } from "../../redux/actions/couponAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import { useDispatch, useSelector  } from "react-redux";
import notify from "../../hook/useNotification";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const AddCouponHook = () => {

  const [couponName, setCouponName] = useState("");
  const [couponDate, setCouponDate] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [couponType, setCouponType] = useState("");

  const [loading, setLoading] = useState(false);
const [show, setShow] = useState(false)
const [coponId, setcoponId] = useState("");

const {t} = useTranslation()

  const handleClose = () => setShow(false);
  const handleShow = (id) => {

    setcoponId(id)
    setShow(true);
    
  } 

  const handleDelete = async () => {
    await dispatch(deleteCoupon(coponId));
    setShow(false);
   notify(t("Delete successfully"), "success");
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);

  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location=useLocation();

  console.log('location',location.pathname);

  const onChangeCouponName = (e) => {
    e.persist();
    setCouponName(e.target.value);
  };
  const onChangeCouponDate = (e) => {
    e.persist();
    setCouponDate(e.target.value);
  };
  const onChangeCouponValue = (e) => {
    e.persist();
    setCouponValue(e.target.value);
  };
  const onChangeCouponType = (e) => {
    e.persist();
    setCouponType(e.target.value);
  };
  const onSubmit = async () => {
    if (
      couponName === "" ||
      !couponType ||
      couponValue <= 0 ||
      couponDate === ""
    ) {
      notify("من فضلك اكمل البيانات", "error");
    }
    setLoading(true);
    await dispatch(
      addCoupon({
        title: couponName.toLowerCase(),
        endDate: couponDate,
        type: couponType,
        value: couponValue,
      })
    );
    setLoading(false);
  };

  const clickHandler = (id) => {
    navigate(`/admin/editcoupon/${id}`);
  };


  const res = useSelector((state) => state.couponReducer.addCoupon);

  console.log('res',res)
  useEffect(() => {
    if (res?.data&&location.pathname=='/admin/addcoupon') {

      if (res && res.success === true) {
        setCouponName("");
        setCouponDate("");
        setCouponValue("");
        setCouponType("");
        notify("تم الحفظ بنجاح", "success");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 1000);
      } else if (
        res &&
        res.data.message === "Coupon already found in this name"
      ) {
        notify("هذا الكوبون مضاف من قبل", "error");
      } else {
        notify("فشل في عملية الاضافه", "error");
      }

    }
  }, [res]);

  const allCoupon = useSelector((state) => state.couponReducer.allCoupon);

  useEffect(() => {
    const get = async () => {
      await dispatch(getAllCoupon());
    };
    get();
  }, []);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  let coupons = null;
  try {
    if (allCoupon && allCoupon.data.length >= 1) coupons = allCoupon.data;
  } catch (e) {}

  return [
    couponName,
    couponDate,
    couponValue,
    couponType,
    onChangeCouponName,
    onChangeCouponDate,
    onChangeCouponValue,
    onChangeCouponType,
    onSubmit,
    coupons,
    clickHandler,
    show,
    handleShow,
    handleClose,
    handleDelete,
    settingsRes,
    loading
    
  ];
};

export default AddCouponHook;
