import { GET_ACCESS_TOKEN_TAOBAO,GET_TAOBAO_PRODUCTS,RESET_TAOBAO_PRODUCTS,SAVE_TAOBAO_PRODUCT } from "../type";



const initial={
        accessTokenObj:{},
        taobaoProducts:{},
        savedProduct:{}
};

const taobaoReducer=(state=initial,action)=>{
    switch (action.type) {
        case GET_ACCESS_TOKEN_TAOBAO:
            return{
                ...state,
                accessTokenObj:action.payload
            }
        case GET_TAOBAO_PRODUCTS:
            return{
                ...state,
                taobaoProducts:action.payload
            }
        case SAVE_TAOBAO_PRODUCT:
            return{
                ...state,
                savedProduct:action.payload
            }
        case RESET_TAOBAO_PRODUCTS:
            return{
                taobaoProducts:{}
            }
    
        default:
            return state;
    }
}

export default taobaoReducer;