import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import add from "../../Images/add.png";
import { useParams } from "react-router-dom";
import MultiImageInput from "react-multiple-image-input";
import { CompactPicker } from "react-color";
import { ToastContainer } from "react-toastify";
import EditProductHook from "../../hook/product/EditProductHook";
import AdminEditSliderHook from "../../hook/admin/AdminEditSliderHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AdminEditSlider = () => {
    const { id } = useParams();
    const [
        title,
    desc,
    showPicker,
    color,
    handleChaneComplete,
    handleSubmit,
    onChangeTitle,
    onChangeDesc,
    onChangePicker,
    check,
    link,
    onChangeLink,
    images,
    setImages,
    link2,
    onChangeLink2
    ] = AdminEditSliderHook(id);
    const settingsRes = useSelector((state) => state.settingsReducers.settings);

    const {t} = useTranslation();

    const maxFileSize = 1 * 1024 * 1024; // 2 MB in bytes
  const getImageSizeFromBase64 = (base64) => {
    // Calculate file size from Base64 string
    const stringLength = base64.length - 'data:image/png;base64,'.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812; // Base64 size in bytes
    return sizeInBytes;
  };

  const handleSetImages = (newImages) => {
    const validatedImages = {};
    for (const [key, base64Image] of Object.entries(newImages)) {
      const imageSize = getImageSizeFromBase64(base64Image);
      if (imageSize <= maxFileSize) {
        validatedImages[key] = base64Image;
      } else {
        alert(`Image size exceeds 1 MB: ${key}`);
      }
    }
    setImages(validatedImages);
  };

    return (
        <div>
            <Row className="justify-content-start ">
                <div className="admin-content-text pb-4"> تعديل شريط الصور - {title}</div>
                <Col sm="6">
                <div className="mt-3" id="images">
                <MultiImageInput
                  images={images}
                  setImages={handleSetImages}
                  theme="light"
                  max={2}
                  // cropConfig={{ crop, ruleOfThirds: true }}
                  allowCrop={false}
                />
              </div>

              <label htmlFor="link" className="d-block mt-2 ">
              {" "}
              {t("ads link")}{" "}
            </label>
            <input
              id="link"
              value={link}
              onChange={onChangeLink}
              type="text"
              className="input-form d-block mt-2 px-3"
              placeholder={t("Link")}
            />
  
            <label htmlFor="link" className="d-block mt-2 ">
            {" "}
            {t("ads link")}{"2 "}
          </label>
          <input
            id="link"
            value={link2}
            onChange={onChangeLink2}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Link")}
          />

          <label htmlFor="title" className="d-block mt-2 ">
          {" "}
          {t("address")}{" "}
        </label>
        <input
          id="title"
          value={title}
          onChange={onChangeTitle}
          type="text"
          className="input-form d-block mt-2 px-3"
          placeholder={t("address")}
        />
        <label htmlFor="description" className="d-block mt-2 ">
          {t("details")} {" "}
          {" "}
        </label>
        <input
          id="description"
          value={desc}
          onChange={onChangeDesc}
          type="text"
          className="input-form d-block mt-2 px-3"
          placeholder={t("details")}
        />
  

                  
                
                

                    <div className="text-form mt-3 "> لون الخلفية</div>
                    <div className="mt-1 d-flex">
                        {color && (
                            <div
                                className="color ms-2 border  mt-1"
                                style={{ backgroundColor: color }}
                            ></div>
                        )}
                        <img
                            src={add}
                            alt=""
                            width="30px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={onChangePicker}
                        />
                        {showPicker === true ? (
                            <CompactPicker onChangeComplete={handleChaneComplete} />
                        ) : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="8" className="d-flex justify-content-end ">
                    {check === true ? (
                        <Spinner animation="border" variant="primary" />
                    ) : (
                        <button onClick={handleSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
                            حفظ التعديلات
                        </button>
                    )}
                </Col>
            </Row>
            <ToastContainer />
        </div>
    );
};

export default AdminEditSlider;
