import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import Pagination from "../../Components/Utilities/Pagination";
import ViewAllProductsByCategoryHook from "../../hook/product/ViewAllProductsByCategoryHook";

import { Helmet } from "react-helmet";
import SideFilter from "../../Components/Utilities/SideFilter";
import ViewSearchProductHooks from "../../hook/product/ViewSearchProductHooks";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";

const ProductsByCategory = () => {
  const { id } = useParams();
  const [, , , catById, settingsRes] = ViewAllProductsByCategoryHook(id);
  const [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    offers,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    minPrice,
    maxPrice,
    loadingSpinner,
  ] = ViewSearchProductHooks("", undefined, id);
  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  let hideCategory = true;

  return (
    <div style={{ minHeight: "670px" }}>
      <Helmet>
        <title>
          {`  ${items[0]?.category.nameAr || " "} ${items[0] ? "-" : " "} ${
            settingsRes.data?.titleAr
          }`}{" "}
        </title>
      </Helmet>
      <CategoryHeader />
      <div
        style={{
          background: settingsRes?.data?.secondaryColor,
          height: "296px",
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            height: "296px",
            alignItems: "center",
          }}
        >
          <img
            alt="category"
            src={catById.image}
            width="210px"
            className="mx-3"
            style={{objectFit:'contain'}}
          />
          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
            {catById.nameAr}
          </div>
        </div>
      </div>
      <Container>
        <Row>
          <Col md="2" sm="2"  className="d-flex ">
            <SideFilter
              props={{
                selectedCategorySearch,
                selectedBrandSearch,
                search,
                onChangeSetMinPrice,
                onChangeSetMaxPrice,
                hideCategory,
              }}
            />
          </Col>
          <Col md="10" sm="10" xs="12" >
            {loadingSpinner === true ? (
              <LoadingSpinner />
            ) : (
              <CardProductsContainer products={items} title="" btntitle="" />
            )}
          </Col>
        </Row>
       {
        items&&<Pagination pageCount={pageCount} onPress={onPress} />
       } 
      </Container>
    </div>
  );
};

export default ProductsByCategory;
