import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import avatar from "../../Images/avatar.png";
import AddBrandHook from "../../hook/brand/AddBrandHook";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const AdminAddBrand = () => {
  const { t } = useTranslation() ;
  const [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    settingsRes,
  ] = AddBrandHook();

  

  return (
    <div>
      <Helmet>
        <title>{`${t("add brandd")} - ${Cookies.get("i18next")==="ar"?settingsRes.data?.titleAr:settingsRes.data?.titleEn}`} </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("add brand")}</div>
        <Col sm="8">
          <div className="text-form pb-2">{t("brand image")}</div>
          {/* <img src={avatar} alt="" height="100px" width="120px" /> */}
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="brandAr" className="d-block mt-2 ">
          {t("brand name in arabic")}{" "}
          </label>
          <input
            id="brandAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("brand name in arabic")}
          />
          <label htmlFor="brandEn" className="d-block mt-2 ">
          {t("brand name in english")}{" "}
          </label>
          <input
            id="brandEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("brand name in english")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>
      {isPress ? (
        loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <h4>{t("done")}</h4>
        )
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default AdminAddBrand;
