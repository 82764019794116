import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminAddUserHook from "../../hook/admin/AdminAddUserHook";
import AddCouponHook from "../../hook/coupon/AddCouponHook";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const AdminAddCopon = () => {
  const { t } = useTranslation();
  const dateRef = useRef();
  const [
    couponName,
    couponDate,
    couponValue,
    couponType,
    onChangeCouponName,
    onChangeCouponDate,
    onChangeCouponValue,
    onChangeCouponType,
    onSubmit,
    coupons,
    clickHandler,
    show,
    handleShow,
    handleClose,
    handleDelete,
    settingsRes,
    loading
  ] = AddCouponHook();

  return (
    <div>
      <Helmet>
        <title>{`  ${t("add copon")} - ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("add new copon")}</div>
        <Col sm="8">
          <label htmlFor="couponName" className="d-block mt-2 ">
            {t("copon discound name")}{" "}
          </label>
          <input
            id="couponName"
            value={couponName}
            onChange={onChangeCouponName}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("copon discound name")}
          />
          <label htmlFor="couponValue" className="d-block mt-2 ">
            {" "}
            {t("discount value")}{" "}
          </label>
          <input
            id="couponValue"
            value={couponValue}
            onChange={onChangeCouponValue}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("discount value")}
          />
          <label htmlFor="type" className="d-block mt-2 ">
            {" "}
            {t("discound value type")}{" "}
          </label>
          <select
            value={couponType}
            onChange={onChangeCouponType}
            name="type"
            id="type"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">{t("discound value type")}</option>
            <option value="1">{t("value")}</option>
            <option value="2">{t("percent")}</option>
          </select>
          <label htmlFor="date" className="d-block mt-2 ">
            {" "}
            {t("experation date")}{" "}
          </label>
          <input
            id="date"
            ref={dateRef}
            value={couponDate}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("experation date")}
            onChange={onChangeCouponDate}
            onFocus={() => (dateRef.current.type = "date")}
            onBlur={() => (dateRef.current.type = "text")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            disabled={loading}
          >
            { loading ? <Spinner /> :t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddCopon;
