import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import CategoryContainer from "../../Components/Category/CategoryContainer";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";
import Pagination from "../../Components/Utilities/Pagination";
import CatPageHook from "../../hook/category/CatPageHook";
const CategoryPage = () => {
  const [category, loading, loadingSpinner, colors] = CatPageHook();

  const [waitTime, setWaitTime] = useState(true);

  setTimeout(() => {
    setWaitTime(false);
    // dispatch(EndWaitTimeForHomePage());
  }, 3000);


  return (

    <Col md="12" sm="4">
      {loading === true ? (
        <LoadingSpinner />
      ) : (
        <>
          <div style={{ display: waitTime == true ? 'block' : 'none' }}>
            <LoadingSpinner />
          </div>
          <div style={{ display: waitTime == true ? 'none' : 'block' }}>
            <CategoryContainer category={category} loading={loading} />
          </div>

        </>
      )}
    </Col>


  );
};

export default CategoryPage;
